import './App.scss';
import MobileNav from './components/MobileNav';
import Navbar from './components/Navbar';
import Footer from './pages/Footer';
import Home from './pages/Home';
import Impact from './pages/Impact';
import MiniProgram from './pages/MiniProgram';
import Mission from './pages/Mission';
import OurStoryPage from './pages/OurStoryPage';
import ResidentSay from './pages/ResidentSay';
import RoadMap from './pages/RoadMap';
import SSCoin from './pages/SSCoin';
import './styles/Media.scss';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <MobileNav/>
      <Home/>
      <OurStoryPage/>
      <Mission/>
      <Impact/>
      <ResidentSay/>
      <MiniProgram/>
      <SSCoin/>
      <RoadMap/>
      <Footer/>
    </div>
  );
}

export default App;
