import React, { useEffect, useState } from "react";
import "./MiniProgram.scss";
import MINIPROGRAM from "../assets/miniprogram.png";
import MOBILEMINIPROGRAM from "../assets/mobileMiniProgram.png";

import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const MiniProgram = () => {
    const [screenSize, setScreenSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    useEffect(() => {
        const handleResize = () => {
            setScreenSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        <div id="miniProgram">
            <div className="body">
                <Reveal keyframes={customAnimation}>
                    <div className="top">
                        <h1>WeChat MiniProgram Design</h1>
                        <p>
                            Expected to launch in September in WeChat platform{" "}
                        </p>
                    </div>
                </Reveal>
                <Reveal keyframes={customAnimation}>
                    <div className="bottom">
                        <img
                            src={
                                screenSize.width > 1050
                                    ? MINIPROGRAM
                                    : MOBILEMINIPROGRAM
                            }
                            alt="mini program"
                        />
                    </div>
                </Reveal>
            </div>
        </div>
    );
};

export default MiniProgram;
