import React, { useState, useEffect } from "react";
import ROADMAP from "../assets/RoadMap.png";
import MOBILEROADMAP from "../assets/mobileRoadMap.png";
import "./RoadMap.scss";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { Element } from "react-scroll";

const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const RoadMap = () => {
    const [screenSize, setScreenSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    useEffect(() => {
        const handleResize = () => {
            setScreenSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        <Element name="roadMap">
            <Reveal keyframes={customAnimation}>
                <div id="roadMap">
                    <img
                        src={screenSize.width > 1050 ? ROADMAP : MOBILEROADMAP}
                        alt="road map"
                    />
                </div>
            </Reveal>
        </Element>
    );
};

export default RoadMap;
