import './ContactUsButton.scss';
import { Link } from 'react-scroll';
const ContactUsButton = () => {
    return (
        <Link to="footer" smooth={true} duration={500}>
            <div className="Navbar__Link Homecontact">Contact us</div>
        </Link>
    );
};


export default ContactUsButton;