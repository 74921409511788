import React from "react";
import "./Impact.scss";

import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { Element } from "react-scroll";

const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const IMPACTS = [
    {
        numberOfImpact: "100+",
        message: "Dedicated Volunteer",
    },
    {
        numberOfImpact: "30k+",
        message: "Follower in Xiaohongshu (Chinese Popular social media)",
    },
    {
        numberOfImpact: "2000+",
        message: "high-quality properties",
    },
    {
        numberOfImpact: "8500+",
        message: "Community members in the house exchange program",
    },
];

const ImpactCards = () => {
    return IMPACTS.map((impact) => {
        return (
            <div className="impactCard">
                <h1>{impact.numberOfImpact}</h1>
                <p>{impact.message}</p>
            </div>
        );
    });
};

const Impact = () => {
    return (
        <Element name="impactPage">
            <div id="impactPage">
                <div className="body">
                    <Reveal keyframes={customAnimation} cascade duration={300}>
                        <h1>Impact</h1>
                        <p className="subheadLine">
                            Since its launch just a month and a half ago,
                            SheStays has rapidly amassed over{" "}
                            <b>30,000 followers</b> on its official Xiaohongshu
                            account.
                        </p>
                        <p className="subheadLine">
                            The community engaging in the house exchange program
                            has grown to over 8,500 members.
                        </p>
                    </Reveal>
                    <div className="impactCards">
                        <Reveal
                            keyframes={customAnimation}
                            cascade
                            duration={300}
                        >
                            {ImpactCards()}
                        </Reveal>
                    </div>
                    <Reveal keyframes={customAnimation}>
                        <span>Data collected on Aug 28, 2024</span>
                    </Reveal>
                </div>
            </div>
        </Element>
    );
};

export default Impact;
