import { keyframes } from "@emotion/react";
import "./MobileNav.scss";

import React from "react";
import { Link } from "react-scroll";
import Reveal from "react-awesome-reveal";
const MobileNav = () => {

    const customAnimation = keyframes`
    from {
        opacity: 0;
        transform: translateY(-50px) scale(.5);
    }

    to {
        opacity: 1;
        transform: translateX(0) scale(1);
    }
    `;
    const targetIds =[
        {
            id: "ourStory",
            text: "Our Story"
        },
        {
            id: "missionPage",
            text: "Mission"
        },
        {
            id: "impactPage",
            text: "Impact"
        },
        {
            id: "residentSay",
            text: "Resident Say"
        },
        {
            id: "miniProgram",
            text: "Mini Program"
        },
        {
            id: "SSCoin",
            text: "SSCoin"
        },
        {
            id: "roadMap",
            text: "Road Map"
        },
    ]

    const renderDots = () =>{
        return targetIds.map(target=> {
            return (
                <Link
                    to={target.id}
                    smooth={true}
                    duration={500}
                    activeClass="active"
                    spy={true}
                >
                    <div className="circle"></div>
                </Link>
            )
        })
    }
    return (
        <div id="mobileNav">
            <div className="body">
            <Reveal keyframes={customAnimation} cascade duration={200}>
                {renderDots()}
            </Reveal>
            </div>
        </div>
    );
};

export default MobileNav;
