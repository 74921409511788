import React from "react";
import OURSTORYIMG from "../assets/OurStory.png";
import LOCATIONICON from "../assets/union.png";
// import USERICON from "../assets/Ellipse.png";
import TANGERINE from "../assets/trangerine.png";
import USER1 from '../assets/user1.webp'
import USER2 from '../assets/user2.webp'
import USER3 from '../assets/user3.webp'

import "./ResidentSay.scss";

import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
const ResidentSay = () => {
    return (
        <div id="residentSay">
            <div className="body">
                <Reveal keyframes={customAnimation}>
                    <div className="left">
                        <img src={OURSTORYIMG} alt="our story" />
                    </div>
                </Reveal>
                <div className="right">
                    <Reveal keyframes={customAnimation}>
                        <h1>What Our Sisters Say</h1>
                    </Reveal>
                    <MessageCard />
                </div>
            </div>
        </div>
    );
};

const MessageCard = () => {
    const messages = [
        {
            name: "Tangerine",
            message:
                "This stay was like a great ship on the stormy seas, providing me with a reassuring home in Malta.",
            location: "Netherlands",
            avatar: TANGERINE,
        },
        {
            name: "Cici",
            message:
                "Being able to help other girls travel in a low-cost and safe way gives me a deep sense of fulfillment.",
            location: "China",
            avatar: USER1,
        },
        {
            name: "上船",
            message:
                "I hope to offer accommodation to more women as I expand to more rooms, and I hope they will come to love the spaces I provide. My aim is to create a warm, cozy haven for them.",
            location: "Malaysia",
            avatar: USER2,
        },
        {
            name: "Avaritia",
            message:
                "This experience was novel for me, like opening a window to a new world, allowing me to meet people with completely different life experiences from my own.",
            location: "France",
            avatar: USER3,
        },
    ];

    return (
        <div className="messageCard">
            <Reveal keyframes={customAnimation} cascade duration={500}>
                {messages.map((message) => (
                    <div className="card">
                        <div className="avatar">
                            <img src={message.avatar} alt="avatar" />
                        </div>
                        <div className="message">
                            <div className="top">
                                <div className="name">
                                    <p>{message.name}</p>
                                </div>
                                <div className="location">
                                    <img src={LOCATIONICON} alt="location" />
                                    <p>{message.location}</p>
                                </div>
                            </div>
                            <div className="bottom">
                                <p>{message.message}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </Reveal>
        </div>
    );
};

export default ResidentSay;
