import React from "react";
import "./OurStoryPage.scss";
import OURSTORYIMG from "../assets/Our story.png";
import OURSTORYIMGAIRPLANE from "../assets/our story illustration.png";

import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { Element } from "react-scroll";

const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const OurStoryPage = () => {
    return (
        <Element name="ourStory">
            <div id="ourStory">
                <div className="storyContent">
                    <Reveal keyframes={customAnimation}>
                        <div className="left">
                            <img src={OURSTORYIMG} alt="ourstory image" />
                        </div>
                    </Reveal>
                    <div className="right">
                        <div className="contentBox">
                            <Reveal
                                keyframes={customAnimation}
                                cascade
                                duration={300}
                            >
                                <img
                                    src={OURSTORYIMGAIRPLANE}
                                    alt="ourstoryimgairplane"
                                />
                                <h1>Our Story</h1>
                                <p>
                                    SheStays is a unique accommodation exchange
                                    platform specifically designed for women
                                    around the world, dedicated to providing
                                    users with a safe, trustworthy, and warm
                                    lodging experience.
                                </p>
                                <p>
                                    Our mission is to empower women to explore
                                    the world and enjoy a new way of traveling.
                                    We welcome not only Chinese women from all
                                    over the globe but also warmly invite women
                                    from abroad to join us.
                                </p>
                                <p>
                                    By exchanging stays with local women, you
                                    can step out confidently, immersing yourself
                                    in local culture and customs in an
                                    eco-friendly and cost-effective way.
                                </p>
                            </Reveal>
                        </div>
                    </div>
                </div>
            </div>
        </Element>
    );
};

export default OurStoryPage;
