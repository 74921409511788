import React from "react";
import "./Navbar.scss";
import SHESTAYSLOGO from "../assets/SS-Logo_website 1.png";
import { Link } from "react-scroll";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
const Navbar = () => {
    const customAnimation = keyframes`
    from {
        opacity: 0;
        transform: translateY(-50px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
    `;
    return (
        <div id="Navbar">
            <Reveal keyframes={customAnimation} duration={500}>
            <Link to="home" smooth={true} duration={500}>
                <div id="logo">
                    <div className="Navbar__Link Navbar__Link-brand">
                        <img src={SHESTAYSLOGO} alt="she stays logo" />
                    </div>
                </div>
            </Link> 
            </Reveal>
            <div id="navLinks">
                <Reveal keyframes={customAnimation} cascade duration={200}>
                    <Link to="ourStory" smooth={true} activeClass="active" duration={500}>
                        <div className="Navbar__Link hover-link">Our Story</div>
                    </Link>
                    <Link to="impactPage" smooth={true} activeClass="active" duration={500}>
                        <div className="Navbar__Link hover-link">Impact</div>
                    </Link>
                    <Link to="miniProgram" smooth={true} activeClass="active" duration={500}>
                        <div className="Navbar__Link hover-link">
                            MiniProgram
                        </div>
                    </Link>
                    <Link to="SSCoin" smooth={true} activeClass="active" duration={500}>
                        <div className="Navbar__Link hover-link">SScoin</div>
                    </Link>
                    <Link to="roadMap" smooth={true} activeClass="active" duration={500}>
                        <div className="Navbar__Link hover-link">Roadmap</div>
                    </Link>
                    <Link to="footer" smooth={true} duration={500}>
                        <div className="Navbar__Link contact">Contact us</div>
                    </Link>
                </Reveal>
            </div>
        </div>
    );
};

export default Navbar;
