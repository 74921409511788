import React from "react";
import landingBG from "../assets/landingBG.png";
import "./Home.scss";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import './Home.scss';
import ContactUsButton  from "../components/ContactUsButton.js";

const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px) scale(1.3);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`;

const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px) scale(1.2);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`;
const HeadLine = () => {
    return (
        <div id="headLine">
            <Reveal keyframes={customAnimation}>
                <h1>Welcome to SheStays</h1>
                <p>
                    Empowering women through <br />
                    <b>sustainable housing</b> solutions.
                </p>
            </Reveal>
            <ContactUsButton/>
        </div>
    );
};

const Home = () => {
    return (
        <div id="home">
            <Reveal keyframes={slideUp}>
                <img src={landingBG} alt="landingBG" />
            </Reveal>
            <HeadLine />
        </div>
    );
};

export default Home;
