import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import React from "react";
import "./SSCoin.scss";
const SSCOINIMG = require("../assets/SSCoin/sscoin icon.png");
const EARNCOIN = require("../assets/SSCoin/earn sscoin.png");
const REGISTERTOEARN = require("../assets/SSCoin/register.png");
const BOOKHOME = require("../assets/SSCoin/book home.png");
const BENEFITS = require("../assets/SSCoin/benefit exchange icon.png");
const DEDUCTIONS = require("../assets/SSCoin/deduction icon.png");
const EVENTS = require("../assets/SSCoin/events icon.png");
const HOTEL = require("../assets/SSCoin/hotel icon.png");
const SWAP = require("../assets/SSCoin/swap home icon.png");

const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const SSCoin = () => {
    return (
        <div id="SSCoin">
            <div className="body">
                <div className="coinImage">
                    <Reveal keyframes={customAnimation} cascade duration={500}>
                        <img src={SSCOINIMG} alt="sscoin icon" />
                        <p>
                            Save every time you swap,
                            <br /> Earn every time you host.
                        </p>
                    </Reveal>
                </div>
                <Reveal keyframes={customAnimation} duration={500}>
                    <h2>How it works</h2>
                </Reveal>
                <Cards />
                <h2 className="howtousesscoin">How to use SScoin</h2>
                <CoinUsage />
            </div>
        </div>
    );
};

const Cards = () => {
    const cards = [
        {
            imagePath: REGISTERTOEARN,
            header: "Register to gain SScoin",
            body: "Register SheStays account and complete identity verification to gain SScoins to apply for swap home.",
        },
        {
            imagePath: BOOKHOME,
            header: "Book desired home by SScoin",
            body: "Check our miniprogram or website, contact hosts, and book available home simply by SScoin.",
        },
        {
            imagePath: EARNCOIN,
            header: "Host and earn SScoin",
            body: "List your property to help more women traveling, make friends, and earn more SScoin!",
        },
    ];
    return (
        <div id="cards">
            <Reveal keyframes={customAnimation} cascade duration={500}>
                {cards.map((card) => (
                    <div className="card">
                        <img src={card.imagePath} alt="cardImage" />
                        <h1>{card.header}</h1>
                        <p>{card.body}</p>
                    </div>
                ))}
            </Reveal>
        </div>
    );
};

const CoinUsage = () => {
    const coinUsage = [
        {
            imagePath: SWAP,
            desc: "House Swap Payments",
        },
        {
            imagePath: BENEFITS,
            desc: "Benefit Exchange",
        },
        {
            imagePath: HOTEL,
            desc: "Partner Hotel/ Homestay Swaps",
        },
        {
            imagePath: EVENTS,
            desc: "Offline Events",
        },
        {
            imagePath: DEDUCTIONS,
            desc: "Deductions for booking ",
        },
    ];
    return (
        <Reveal keyframes={customAnimation} duration={500}>
            <div id="coinusage">
                {coinUsage.map((coin) => (
                    <div className="coin">
                        <img src={coin.imagePath} alt="coinImage" />
                        <h1>{coin.desc}</h1>
                    </div>
                ))}
            </div>
        </Reveal>
    );
};

export default SSCoin;
