import React from "react";
import "./Footer.scss";
import XIAOHONGSHU from "../assets/Logo_xiaohongshu.png";
import FACEBOOK from "../assets/logo_facebook.png";
import INSTAGRAM from "../assets/logo_ins.png";
const Footer = () => {
    return (
        <div id="footer">
            <div className="body">
                <div className="left">
                    <h1>Contact US</h1>
                    <p>
                        For more questions, don’t hesitate to reach out to us.
                        We would be more than willing to chat!
                    </p>
                    <b>Our Email: SheStays@163.com</b>
                </div>
                <div className="right">
                    <h1>Follow our social media:</h1>
                    <MediaLinks />
                </div>
            </div>
        </div>
    );
};

const MediaLinks = () => {
    const mediaLinks = [
        {
            name: "xiaohongshu",
            link: "https://www.xiaohongshu.com/user/profile/5856a43550c4b404949f411d?xhsshare=CopyLink&appuid=571b9c3baed75804c52b33cf&apptime=1725127835&share_id=5c9c0fd0e27549f9b3683a448b9a3f1c",
            imgPath: XIAOHONGSHU,
        },
        {
            name: "facebook",
            link: "https://www.facebook.com/profile.php?id=61561960201942&mibextid=LQQJ4d",
            imgPath: FACEBOOK,
        },
        {
            name: "instagram",
            link: "https://www.instagram.com/she_stays_global?igsh=eWxzNTlkY3JyYnRk",
            imgPath: INSTAGRAM,
        },
    ];
    return (
        <div className="mediaLinks">
            {mediaLinks.map((mediaLink) => (
                <a href={mediaLink.link} target="_blank" rel="noreferrer">
                    <img src={mediaLink.imgPath} alt={mediaLink.name} />
                </a>
            ))}
        </div>
    );
};
export default Footer;
