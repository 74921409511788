import React from "react";
import "./Mission.scss";
import OURCOMMITMENT from "../assets/Our commitment icon.png";
import OURMISSION from "../assets/Our mission icon.png";
import OURVALUE from "../assets/our value icon.png";

import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Missions = [
    {
        headline: "Our Mission",
        body: "Empower women by providing safe and sustainable housing options.",
        imgPath: OURMISSION,
    },
    {
        headline: "Our Values",
        body: "Equality, Diversity, Sustainability.",
        imgPath: OURVALUE,
    },
    {
        headline: "Commitment to Sustainability",
        body: "We are dedicated to eco-friendly practices in all our operations.",
        imgPath: OURCOMMITMENT,
    },
];

const Mission = () => {
    const renderCards = () => {
        return Missions.map((m) => {
            return (
                <div className="card">
                    <img src={m.imgPath} alt={m.headline} />
                    <div>
                        <h1>{m.headline}</h1>
                        <p>{m.body}</p>
                    </div>
                </div>
            );
        });
    };
    return (
        <div id="missionPage">
            <div className="body">
                <Reveal keyframes={customAnimation}>
                    <h1>Our Mission, Values and commitment</h1>
                </Reveal>
                    <div className="missionCards">
                <Reveal keyframes={customAnimation} cascade duration={500} >
                        {renderCards()}
                </Reveal>
                        </div>
            </div>
        </div>
    );
};

export default Mission;
